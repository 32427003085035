<template>

	<div class="outer-wrapper"> 
		<div id="top-nav"> <!-- No need to include this wrapper, if nav elements should overlap below content (e.g. header images) -->
			<TopLeftIcon icon="main" />    
			<router-link :to="{ name: 'Home' }">
				<img src="@/assets/close-icon.png" alt="close" class="close-icon"/>
			</router-link>
		</div>

		<div class="inner-wrapper main">
			<div class="heading-section">
				<h1 class="heading3">Page not found!</h1>
			</div>
			
			<ResultIcon type="fail" message="Sorry, the page you are looking for does not exist." class="result-icon"/>
		</div>

	</div><!-- end of outer wrapper -->

</template>

<script>
import ResultIcon from '@/components/ResultIcon.vue';
import TopLeftIcon from '@/components/TopLeftIcon.vue';

export default {
	components: {
		ResultIcon,
		TopLeftIcon
	},
}
</script>

<style scoped>
	.main {
		text-align: center;
	}
	.main .result-icon {
		margin-top: 40px;
		display: inline-block;
	}
</style>